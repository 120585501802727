<script>
import { onMounted, computed, ref, watch, reactive } from "vue";
import { mapGetters, useStore } from 'vuex';
import { useRouter } from "vue-router";
import { generateImageOss } from "@/helpers/axios/formDataRequest";
import { cloneDeep, debounce, delay, get, isEqual } from "lodash";

import { mapActions } from "vuex";
import i18n from "../i18n";
import { authService } from "@/services/auth.service";
import { ROLES } from '@/constants';
import { facilityService, pushNotificationService } from "@/services";
import Multiselect from "@vueform/multiselect";
import { STORAGE_KEY } from "@/constants";
import Cookies from 'js-cookie';
import { SimpleBar } from "simplebar-vue3";
import Loading from "@/components/Loading.vue";
import { io } from "socket.io-client";

import moment from "moment";
import Swal from "sweetalert2";
import { onClickOutside, useFullscreen } from '@vueuse/core'

/**
 * Nav-bar Component
 */
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const urlAvatar = ref(require("@/assets/images/users/defalt-user.png"));
    const isLoadingMore = ref(false);
    const notificationRef = ref(null)

    const { toggle, isFullscreen } = useFullscreen(null)

    const currentUser = computed(() => store.getters[`auth/currentUser`]);
    const showSelect = computed(() => currentUser.value && currentUser.value.role !== ROLES.FACILITY_ACCOUNT);
    //const notifyData = computed(() => store.getters['settings/getNotify'])
    const notifyData = ref([]);
    const totalUnread = ref(0);
    const totalNotify = ref(0);
    const hasMore = ref(true)
    const isAdmin = computed(() => currentUser.value && currentUser.value.role === ROLES.ADMIN);
    const isAccount = computed(() => currentUser.value && currentUser.value.role === ROLES.FACILITY_ACCOUNT);
    const state = reactive({
      query: {
        page: 1,
        pageSize: 10,
        sortBy: 'createdAt',
        sortOrder: 'DESC'
      },
      selectedNotify: [],
      notify: {
        isShow: false,
        title: '',
        content: ''
      },
      openNotify: false,
      isShowButtonShowMore: false
    })
    const isFacilityManager = computed(() => currentUser.value && currentUser.value.role === ROLES.FACILITY_MANAGER);
    const refreshData = ref(localStorage.getItem('refresh') ? JSON.parse(localStorage.getItem('refresh')) : false);
    const socket = ref(null)

    onClickOutside(notificationRef, (event) => {
      if (event.target && event.target.className && typeof event.target.className !== 'object' && !event.target.className.includes('swal2')) {
        state.openNotify = false
      }
    })

    const changeQueryState = (query) => {
      state.query = {
        ...state.query,
        ...query
      }
    }

    const getUserAvatar = () => {
      const url = currentUser.value.pathAvatar ? generateImageOss(currentUser.value.pathAvatar) : require("@/assets/images/users/defalt-user.png");
      urlAvatar.value = url;

      return url;
    };

    const getProfile = async () => {
      try {
        let res = await authService.getUserProfile();
        if (res && !isEqual(currentUser.value, res)) {
          store.commit('auth/SET_CURRENT_USER', res);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const deleteCookieSNS = (cookieName = 'profile_twitter') => {
      const domain = process.env.VUE_APP_ENV ? '' : '.tokyo-saunist.com'
      Cookies.remove(cookieName, {
        domain
      })
    }
    watch(() => refreshData.value, (newValue) => {
      localStorage.setItem('refresh', newValue);
      store.commit('settings/SET_REFRESH_DATA', newValue);
    });

    const initDataNotify = async () => {
      try {
        const res = await getAllNotify();
        notifyData.value = res.data.map(d => ({
          ...d,
          moment: moment(d.createdAt).fromNow()
        }));
        totalUnread.value = res.notRead;
        totalNotify.value = res.pagination.totalCount;
        hasMore.value = res.pagination.currentPage < res.pagination.totalPage;
        state.isShowButtonShowMore = totalNotify.value > notifyData.value.length;
      } catch (e) {
        console.log('initDataNotify')
      }

    }

    const getAllNotify = async () => {
      try {
        return await pushNotificationService.getAllAlert(state.query);
      } catch (e) {
        console.log(e, '======')
      }
    }

    onMounted(() => {
      getProfile();
      getUserAvatar();
      initDataNotify();
    })

    const handleOpenNotify = () => {
      state.openNotify = !state.openNotify
      moment.locale('ja');
      const tempData = notifyData.value.map((item) => {
        return {
          ...item, moment: moment(item.createdAt).fromNow()
        }
      })

      notifyData.value = cloneDeep(tempData);
      state.isShowButtonShowMore = totalNotify.value > notifyData.value.length;
    }

    const handleLoadMore = async (event) => {
      event.stopPropagation()
      if (state.query.pageSize > 10) {
        state.query.page = (state.query.pageSize / 10) + 1
      } else {
        state.query.page += 1
      }
      state.query.pageSize = 10
      isLoadingMore.value = true;
      const res = await getAllNotify();
      moment.locale('ja');
      const temp = res.data.map((item) => {
        return {
          ...item, moment: moment(item.createdAt).fromNow()
        }
      })

      notifyData.value = notifyData.value.concat(temp);
      totalUnread.value = res.notRead;
      totalNotify.value = res.pagination.totalCount;
      hasMore.value = res.pagination.currentPage < res.pagination.totalPage
      isLoadingMore.value = false;
      state.isShowButtonShowMore = totalNotify.value > notifyData.value.length;
    }

    const handleChangeSelectNotify = (e) => {
      const findIndex = state.selectedNotify.findIndex(item => item === e.target.value);
      if (findIndex > -1) {
        state.selectedNotify.splice(findIndex, 1)
      } else {
        state.selectedNotify.push(e.target.value)
      }
    }

    const handleRemoveNotify = async (event) => {
      event.stopPropagation()
      try {
        const accept = await Swal.fire({
          title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('msg.MSG_070', { num: state.selectedNotify.length })}</h2>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: i18n.global.t('yes'),
          cancelButtonText: i18n.global.t('cancel'),
        });

        if (accept.value) {
          await pushNotificationService.deleteNotificationByIds({ ids: state.selectedNotify })
          state.query.pageSize = state.query.page * state.query.pageSize
          state.query.page = 1
          const res = await getAllNotify();
          totalUnread.value = res.notRead;
          totalNotify.value = res.pagination.totalCount;
          hasMore.value = res.pagination.currentPage < res.pagination.totalPage
          notifyData.value = res.data.map(d => ({
            ...d,
            moment: moment(d.createdAt).fromNow()
          }))
          state.selectedNotify = [];
        } else {
          state.openNotify = true
        }
      } catch (e) {
        store.commit('settings/SET_POPUP', {
          show: true,
          type: 'error',
          message: e.message
        });
      }
    }


    const onReadAlert = debounce(async (item, index) => {
      if (!item.status) {
        try {
          await pushNotificationService.readAlert(item.id)
          const newItem = { ...item, status: true }
          notifyData.value[index] = newItem
          totalUnread.value -= 1
        } catch (e) {
          console.log(e, 'onReadAlert')
        }
      }
    }, 500)

    const cleanDataLogout = async () => {
      store.commit('auth/SET_CURRENT_USER', null);
      store.commit('settings/RESET_FACILITY', '');

      const rememberMe = localStorage.getItem(STORAGE_KEY.REMEMBER_USER) ? JSON.parse(localStorage.getItem(STORAGE_KEY.REMEMBER_USER)) : false;
      const remember_username = localStorage.getItem(STORAGE_KEY.REMEMBER_USERNAME);
      const remember_password = localStorage.getItem(STORAGE_KEY.REMEMBER_PASSWORD);
      const second = localStorage.getItem(STORAGE_KEY.AUTO_REFRESH);
      const refreshDashboard = localStorage.getItem('refresh');
      localStorage.clear();
      localStorage.setItem(STORAGE_KEY.REMEMBER_USER, rememberMe);
      localStorage.setItem(STORAGE_KEY.AUTO_REFRESH, second)
      localStorage.setItem('refresh', refreshDashboard);
      if (rememberMe) {
        localStorage.setItem(STORAGE_KEY.REMEMBER_USERNAME, remember_username);
        localStorage.setItem(STORAGE_KEY.REMEMBER_PASSWORD, remember_password);
      }
      Cookies.remove('token', {
        path: '/',
      })
      deleteCookieSNS();
      await router.replace({ name: 'logout' })
    }

    return {
      socket,
      hasMore,
      notificationRef,
      state,
      currentUser,
      refreshData,
      isAdmin,
      isAccount,
      isFacilityManager,
      showSelect,
      urlAvatar,
      notifyData,
      totalUnread,
      totalNotify,
      isLoadingMore,
      get,
      getUserAvatar,
      getProfile,
      handleOpenNotify,
      handleLoadMore,
      handleChangeSelectNotify,
      handleRemoveNotify,
      initDataNotify,
      onReadAlert,
      cleanDataLogout,
      changeQueryState,
      toggleFullscreen: toggle,
      isFullscreen,
    };

  },
  data() {
    return {
      language: {
        flag: require("@/assets/images/flags/jp.svg"),
        language: "jp",
        title: "Japanese",
      },
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
      optionsFacilities: [],
      selectedFacility: '',
    };
  },
  components: {
    Multiselect,
    SimpleBar,
    Loading
  },
  created() {
    this.getListFacilitiesOption();
  },
  mounted() {
    if (!document.hasFocus()) {
      document.focus?.();
    }
    this.initSocket();
    document.addEventListener("scroll", function () {
      var pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar?.classList.add(
          "topbar-shadow") : pageTopbar?.classList.remove("topbar-shadow");
      }
    });
    document.addEventListener("keydown", this.handleFullscreenKey);

    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);

    this.isCustomDropdown();
    this.selectedFacility = localStorage.getItem('currentFacilityId');
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleFullscreenKey)
  },
  methods: {
    ...mapActions('layout', [
      'changeSidebarColor',
      'changeTopbar',
      'changeMode'
    ]),
    handleFullscreenKey(event) {
      if ((event.key === "Escape" && this.isFullscreen) || event.key === "F11") {
        event.preventDefault();
        this.initFullScreen();
      }
    },

    async logout() {
      try {
        if (this.socket) {
          this.socket.disconnect();
          this.socket.close();
        }
        await authService.logout();
      } catch (e) {
        console.log('write log for login error');
      } finally {
        this.cleanDataLogout();
        this.$notify({
          group: "notifyAlert", // clean only the foo group
          clean: true,
        });
      }
    },
    async getListFacilitiesOption() {
      if (this.currentUser.role !== ROLES.ADMIN) {
        try {
          const res = await facilityService.getOptionsAllFacility({ page: 1, pageSize: 500 });
          this.optionsFacilities = res.data.map(d => ({ ...d, name: d.name.replace(new RegExp(`\\(${d.facilityIdStr}\\)`, 'g'), '').trim() }));
          const currentFacilityId = localStorage.getItem('currentFacilityId');
          if ((!currentFacilityId && res.data.length > 0) || (currentFacilityId && res.data.length > 0 && !res.data.some(fc => isEqual(fc.value, currentFacilityId)))) {
            localStorage.setItem('currentFacilityId', res.data[0].value);
            this.$store.commit('settings/SET_FACILITY_ID', res.data[0].value);
            this.selectedFacility = res.data[0].value;
          }

          if (res.data.length === 0) {
            localStorage.removeItem('currentFacilityId');
            this.$store.commit('settings/SET_FACILITY_ID', null);
            this.selectedFacility = '';
          }

          this.$store.commit('settings/SET_REFRESH_HEADER', false);
        } catch (e) {
          console.log(e)
        }
      }
    },
    changeFacility(value) {
      if (value) {
        localStorage.setItem('currentFacilityId', value);
        this.$store.commit('settings/SET_FACILITY_ID', value);
        this.selectedFacility = value;
        const domain = process.env.VUE_APP_ENV ? '' : '.tokyo-saunist.com'
        Cookies.remove('profile_twitter', {domain})
      }
    },
    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput?.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown?.classList.add("show");
          searchOptions?.classList.remove("d-none");
        } else {
          dropdown?.classList.remove("show");
          searchOptions?.classList.add("d-none");
        }
      });

      searchInput?.addEventListener("keyup", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown?.classList.add("show");
          searchOptions?.classList.remove("d-none");
        } else {
          dropdown?.classList.remove("show");
          searchOptions?.classList.add("d-none");
        }
      });

      searchOptions?.addEventListener("click", () => {
        searchInput.value = "";
        dropdown?.classList.remove("show");
        searchOptions?.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown?.classList.remove("show");
          searchOptions?.classList.add("d-none");
        }
      });
    },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon")?.classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body?.classList.contains("menu") ?
          document.body?.classList.remove("menu") :
          document.body?.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body?.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
            document.documentElement.setAttribute("data-sidebar-size", "") :
            document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body?.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
            document.documentElement.setAttribute("data-sidebar-size", "sm") :
            document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body?.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body?.classList.contains("twocolumn-panel") ?
          document.body?.classList.remove("twocolumn-panel") :
          document.body?.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      this.toggleFullscreen()
      document.body?.classList.toggle("fullscreen-enable");
      this.$store.commit('settings/SET_FULLSCREEN', !this.isFullscreen);
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    toggleDarkMode() {
      if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
        document.documentElement.setAttribute("data-layout-mode", "light");
        this.changeSidebarColor({ sidebarColor: "light" });
        this.changeTopbar({ topbar: "light" });
        this.changeMode({ mode: "light" });
      } else {
        document.documentElement.setAttribute("data-layout-mode", "dark");
        this.changeSidebarColor({ sidebarColor: "dark" });
        this.changeTopbar({ topbar: "dark" });
        this.changeMode({ mode: "dark" });

      }
    },
    getRouteName() {
      //check role then -> navigate route
      return 'facility-info-management';
    },
    showAlert(value) {
      const text = `<p style="font-size: 13px" class="mb-1">${value.body}</p>
                    <p style="font-size: 10px; color: #9497A3; margin-bottom: 0" class="mx-2"><i class="bx bx-time-five" style="padding-right: 5px"></i>${moment(value.createdAt).format('YYYY-MM-DD HH:mm')}</p>`

      this.$notify({
        title: value.title,
        text: text,
        leave: {
          height: 0,
          opacity: 0
        },
        type: 'danger',
        duration: -1,
        group: "notifyAlert"
      });
    },
    newMessage(value) {
      this.showAlert(value)
      this.changeQueryState({
        page: 1,
        pageSize: this.notifyData.length + 1
      })
      this.initDataNotify();
    },
    deleteTwitterCookies() {
      const domain = process.env.VUE_APP_ENV ? '' : '.tokyo-saunist.com'
      Cookies.remove('profile_twitter', {
        domain
      })
    },
    initSocket() {
      if (!this.currentUser || (this.currentUser && this.currentUser.role !== ROLES.FACILITY_MANAGER)) return;
      this.socket = io(process.env.VUE_APP_ENDPOINT_NOTIFY, {
        autoConnect: true,
        transports: ['websocket'],
        path: "/socket",
        query: { deviceId: this.currentUser.id }
      });

      this.socket.on('refreshNotification', this.newMessage);
      this.socket.on('deleteTwitterCookies', this.deleteTwitterCookies);
    }
  },
  computed: {
    ...mapGetters({ refreshHeader: 'settings/getRefreshHeader', fullscreen: 'settings/getFullScreen' })
  },
  watch: {
    refreshHeader: {
      handler(newValue) {
        if (newValue) this.getListFacilitiesOption()
      },
    },
    isFullscreen(newValue) {
      // Escape the fullscreen
      if (!newValue) {
        document.body?.classList?.remove('fullscreen-enable')
      }
      delay(() => this.$store.commit('settings/SET_FULLSCREEN', newValue), 400)
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo/TS-Symbol-bk.svg" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo/TS-Logo-2-bk.svg" alt="" height="17" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo/TS-Symbol-bk.svg" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo/TS-Logo-2-bk.svg" alt="" height="17" />
              </span>
            </router-link>
          </div>

          <!-- Change small/large sidebar -->
          <button type="button" class="
              btn btn-sm
              px-3
              fs-16
              header-item
              vertical-menu-btn
              topnav-hamburger
            " id="topnav-hamburger-icon">
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

        </div>

        <div v-if="currentUser && !isAdmin" class="d-flex align-items-center justify-content-center"
          style="flex: auto; max-width: 450px">
          <Multiselect v-if="showSelect" v-model="selectedFacility" :options="optionsFacilities" class="multiselect-input"
            :track-by="'name'" :label="'name'" :multiple="false" :searchable="true" @change="changeFacility"
            style="padding: 0;" :can-clear="false" :can-deselect="false">
            <template #noresults>
              <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
            </template>
            <template #nooptions>
              <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
            </template>
          </Multiselect>

          <div :title="currentUser.facility?.name" class="navbar-facility-name text-truncate" v-else>
            {{ currentUser.facility?.name }}
          </div>
        </div>

        <div class="d-flex align-items-center">

          <!-- Change refresh data -->
          <div v-if="$route.name == 'default'" class="ms-1 header-item d-none d-sm-flex mr-1">
            <div class="form-check form-switch form-switch-md" dir="ltr">
              <input v-model="refreshData" type="checkbox" class="form-check-input" id="customSwitchsizemd">
              <label class="form-check-label" for="customSwitchsizemd"></label>
            </div>
          </div>

          <!-- Change language -->
          <div class="dropdown ms-1 topbar-head-dropdown header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img id="header-lang-img" src="@/assets/images/flags/jp.svg" alt="Header Language" height="20"
                class="rounded" />
            </button>
          </div>

          <!-- Click full screen -->
          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-toggle="fullscreen" @click="initFullScreen">
              <i class="bx bx-fullscreen fs-22"></i>
            </button>
          </div>

          <!-- Change dark/light mode -->
          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class=" btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
              @click="toggleDarkMode">
              <i class="bx bx-moon fs-22"></i>
            </button>
          </div>

          <!-- Notifications -->
          <div ref="notificationRef" class="dropdown topbar-head-dropdown ms-1 header-item position-relative">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              @click="handleOpenNotify">
              <i class="bx bx-bell fs-22"></i>
              <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                {{ totalUnread }}</span>
            </button>

            <div v-if="state.openNotify" :class="`notification-container p-0 ${state.openNotify ? 'show' : ''}`"
              aria-labelledby="page-header-notifications-dropdown">
              <div class="dropdown-head bg-primary bg-pattern rounded-top">
                <div class="p-3">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="m-0 fs-16 fw-semibold text-white">
                        通知
                      </h6>
                    </div>
                    <div class="col-auto dropdown-tabs">
                      <span class="badge bg-white text-body fs-13"> {{ totalUnread }} 未読</span>
                    </div>
                  </div>
                </div>

                <div class="px-2 pt-2">
                  <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true"
                    id="notificationItemsTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab" aria-selected="true"
                        @click.capture.stop>
                        すべて ({{ totalNotify }})
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-content" id="event">
                <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                  <SimpleBar data-simplebar class="pe-2" style="overflow-x: hidden;">
                    <div v-if="notifyData.length" style="overflow-x: hidden; max-height: 300px;">
                      <div
                        :class="`text-reset notification-item d-block dropdown-item position-relative ${state.selectedNotify.includes(item.id) ? 'bg-gray-highlight' : ''}`"
                        @click="() => onReadAlert(item, index)" v-for="(item, index) in notifyData" :key="index">
                        <div class="d-flex gap-1">
                          <div v-if="!item.status" class="text-primary fs-16 pt-1">&#x2022;</div>
                          <div class="avatar-xs me-3">
                            <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">
                              <i class="bx bx-badge-check"></i>
                            </span>
                          </div>
                          <div class="flex-1">
                            <a href="#" class="stretched-link">
                              <h6 class="mt-0 mb-0 lh-base">
                                {{ item.notification.title }}
                              </h6>
                              <span class="detail-notify">発生場所: {{ get(item, 'notification.facility.name', '') }}
                                {{ get(item, 'notification.roomName', '') }}</span>
                              <!--                              <p v-html="item.notification.description"></p>-->
                            </a>
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted mt-2">
                              <span><i class="mdi mdi-clock-outline"></i> {{ item.moment }}</span>
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <input class="form-check-input" type="checkbox" :value="item.id"
                              :checked="(state.selectedNotify.findIndex(value => value === item.id) >= 0)"
                              @change="handleChangeSelectNotify" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 py-2 text-center">
                        <b-button style="min-width: 200px;" class="btn btn-soft-success waves-effect waves-light border-0"
                          v-if="state.isShowButtonShowMore" @click="handleLoadMore">
                          <Loading width="14" height="14" v-if="isLoadingMore" />
                          もっと見る
                        </b-button>
                      </div>
                    </div>
                    <div class="text-center view-all row">
                      <div v-if="state.selectedNotify.length > 0"
                        class="col-12 border-top justify-content-center align-items-center bg-white pt-1">
                        <div class="d-flex align-items-center justify-content-center py-2" style="column-gap: 20px">
                          <span>{{ state.selectedNotify.length }}つを選択した</span>
                          <span class="text-danger cursor-pointer" @click="handleRemoveNotify">削除</span>
                        </div>
                      </div>
                    </div>
                    <div v-if="notifyData.length === 0" style="min-height: 250px;">
                      <div class="w-sm-50 pt-5 mx-auto justify-content-center" style="width:40px;">
                        <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                      </div>
                      <div class="text-center pb-5 mt-4 justify-content-center">
                        <h6 class="fs-14 fw-semibold lh-base">通知がありません</h6>
                      </div>
                    </div>
                  </SimpleBar>
                </div>
              </div>
            </div>

          </div>

          <div class="dropdown ms-sm-3 header-item topbar-user">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span v-if="currentUser" class="d-flex align-items-center">
                <img class="rounded-circle header-profile-user" :src="urlAvatar" alt="Header Avatar" />
                <span class="text-start ms-xl-2">
                  <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                    {{ currentUser.name }}
                  </span>
                  <!-- <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> -->
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <router-link class="dropdown-item" :to="{ name: 'profile' }"><i
                  class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Profile</span>
              </router-link>
              <div class="dropdown-divider"></div>
              <router-link v-if="!isAdmin" class="dropdown-item" :to="{ name: getRouteName() }">
                <i class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Setting</span>
              </router-link>
              <button class="dropdown-item" @click="logout"><i
                  class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">Logout</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.multiselect-input ::v-deep {
  border: none;

  .multiselect-wrapper {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    min-height: calc(var(--ms-border-width, 1px)*2 + var(--ms-font-size, 1rem)*var(--ms-line-height, 1.375) + var(--ms-py, .5rem)*2 - 2px);
    outline: none;
    position: relative;
    width: 100%;

    .multiselect-search {
      background-color: var(--vz-topbar-search-bg) !important;
      color: var(--vz-topbar-search-color) !important;
    }

    .multiselect-single-label {
      width: 100%;
      justify-content: center;
      color: var(--vz-topbar-search-color) !important;
      padding-right: var(--ms-px,.875rem);
    }

    .multiselect-caret {
      background: var(--vz-multi-select-caret-color);
    }
  }

}

.navbar-facility-name {
  background-color: #f3f3f9;
  font-size: 20px;
  color: #495057;
  font-weight: bold;
  width: 550px;
  text-align: center;
}

.select-facility-name {
  border: none;
  max-height: 30px;
  background-color: rgb(243, 243, 248) !important;
  padding: 0;
  font-size: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 2s;
}

.bg-gray-highlight {
  background-color: #EDF0F5
}

.notification-container {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(0px, 58px);
  display: none;
  width: 320px;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  animation-name: DropDownSlide;
  z-index: 1000;
  animation-duration: .3s;
  animation-fill-mode: both;
  min-width: 10rem;
  font-size: 0.825rem;
  color: var(--vz-body-color);
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: var(--vz-dropdown-border-width) solid var(--vz-border-color);
  border-radius: 0.3rem;
}

.notification-container.show {
  display: block;
  top: 13px;
}

.detail-notify {
  color: #585D63;
  font-size: 13px;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
